import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { UserPage } from './pages/user';
import { FormType } from './types/user';

declare global {
    interface Window {
        formType: FormType;
    }
}

const formType = window.formType || FormType.SIGN_IN;

ReactDOM.render(
    <UserPage formType={formType} />,
    document.querySelector('#root')
);