import * as React from "react";
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { validateEmail, validatePassword } from '../../utils/validate'; // Ensure these are exported correctly
import { Copyright } from "../headersMessaging/copyright";
import { Banner } from '../../components/headersMessaging/banner';
import { AlertColor } from '@mui/material';

const theme = createTheme();

function Register() {
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [responseMessage, setResponseMessage] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const [severity, setSeverity] = useState("info" as AlertColor);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const password = data.get('password') as string;

    let isValid = true;
    if (!validateEmail(email)) {
      setEmailError('Invalid email address');
      isValid = false;
    } else {
      setEmailError('');
    }

    const passwordValidation = validatePassword(password);
    if (!passwordValidation.isValid) {
      setPasswordError(passwordValidation.errors.join(' '));
      isValid = false;
    } else {
      setPasswordError('');
    }

    const token = await window.grecaptcha.execute("6Le9aOApAAAAAJhEPsPEMFDhda3_nmKRMLE1b3zB", { action: 'register' });

    if (!token) {
      displayBanner('Please complete the CAPTCHA', "error");
      isValid = false;
    }

    if (isValid) {
      const postData = {
        username: email,
        password: password,
        captchaToken: token
      };

      fetch('/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      })
        .then(response => response.json())
        .then(data => {
          if (data.access_token) {
            document.cookie = `token=${data.access_token}`;
            localStorage.setItem('token', data.access_token);
            window.location.href = '/profile?message=Please%20validate%20your%20email.';
          } else {
            displayBanner(data.error, "error");
          }
        });
    }
  };

  const displayBanner = (message: string, severity: AlertColor = "info") => {
    setResponseMessage(message);
    setSeverity(severity);
    setShowBanner(true);
  };

  return (
    <ThemeProvider theme={theme}>
      {showBanner && (
        <Banner
          message={responseMessage}
          severity={severity}
          open={true}
        />
      )}
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} sx={{
          backgroundImage: 'url(https://www.codehawke.com/img/banner/hawk.webp)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }} />
        <Grid item xs={12} sm={8} md={5} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    error={!!emailError}
                    helperText={emailError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    error={!!passwordError}
                    helperText={passwordError}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/login" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export { Register };
