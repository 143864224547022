import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright } from "../headersMessaging/copyright";
import { validateEmail } from '../../utils/validate';
import { Banner } from '../../components/headersMessaging/banner';

const theme = createTheme();

function ForgotPassword() {
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState("");
  const [showBanner, setShowBanner] = React.useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const token = await window.grecaptcha.execute("6Le9aOApAAAAAJhEPsPEMFDhda3_nmKRMLE1b3zB", { action: 'register' });

    if (!token) {
      displayBanner('Please complete the CAPTCHA');
    }

    if (!validateEmail(email)) {
      setError(true);
      setErrorMessage('Invalid email address');
      return; // Stop the function if the email is invalid
    }
    
    // If the email is valid, reset error states and proceed with the POST request
    setError(false);
    setErrorMessage('');

    try {
      const response = await fetch('/auth/forgot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email, captchaToken: token })
      });
      const data = await response.json();
      if (data.message) {
        displayBanner(data.message);
      } else {
        throw new Error(data.error || 'Failed to send reset link');
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error.message);
    }
  };

  const displayBanner = (message: string) => {
    setResponseMessage(message);
    setShowBanner(true);
    };

  return (
    <ThemeProvider theme={theme}>
        {showBanner ? (
        <Banner
          message={responseMessage}
          severity="info"
          open={true}
        />
      ) : (
        ''
      )}
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://www.codehawke.com/img/banner/hawk.webp)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                error={error}
                helperText={errorMessage}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Send Reset Link
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/login" variant="body2">
                    Back to Sign In
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export { 
    ForgotPassword
};
