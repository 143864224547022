import * as React from "react";
import { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert';
import * as DOMPurify from 'dompurify';

interface BannerProps {
  message?: string;
  severity?: AlertColor;
  open?: boolean;
}

const Banner: React.FC<BannerProps> = (props) => {
  const { message: initialMessage, severity: initialSeverity, open: initialOpen } = props;
  const [open, setOpen] = useState<boolean>(initialOpen || false);
  const [message, setMessage] = useState<string>(initialMessage || '');
  const [severity, setSeverity] = useState<AlertColor>(initialSeverity || 'info');

  const createMarkup = () => {
    return { __html: DOMPurify.sanitize(message) };
  };

  // Handle external changes to props
  useEffect(() => {
    if (typeof initialOpen !== 'undefined') setOpen(initialOpen);
    if (initialMessage) setMessage(initialMessage);
    if (initialSeverity) setSeverity(initialSeverity);
  }, [initialOpen, initialMessage, initialSeverity]);

  // Event listener for global event triggers
  useEffect(() => {
    const handleEvent = (event: CustomEvent) => {
      setMessage(event.detail.message);
      setSeverity(event.detail.severity);
      setOpen(true);
    };

    window.addEventListener('displayBanner', handleEvent as EventListener);
    return () => {
      window.removeEventListener('displayBanner', handleEvent as EventListener);
    };
  }, []);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        <div dangerouslySetInnerHTML={createMarkup()} />
      </Alert>
    </Snackbar>
  );
};

export {
    Banner
};
