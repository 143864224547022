import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright } from '../headersMessaging/copyright';
import { Banner } from '../../components/headersMessaging/banner';

const theme = createTheme();

function ResetPassword() {
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState("");
  const [showBanner, setShowBanner] = React.useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(false);

    // Check if passwords match
    if (newPassword !== confirmPassword) {
      setError(true);
      setErrorMessage('Passwords do not match.');
      return;
    }

    // Access the current URL from the window.location object
    const currentUrl = window.location.href;

    // Create a URL object using the current URL
    const urlObj = new URL(currentUrl);

    // Use URLSearchParams to extract query parameters
    const params = new URLSearchParams(urlObj.search);

    // Get the 'id' query parameter
    const id = params.get('id');

    fetch('/auth/reset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        password: newPassword,
        emailHash: id
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
            displayBanner("Your password has been reset successfully.");
            setTimeout(() => {
                window.location.href = "/profile";
            }, 5000);
        } else {
          setError(true);
          setErrorMessage(data.error || 'Failed to reset password');
        }
      });
  };

const displayBanner = (message: string) => {
    setResponseMessage(message);
    setShowBanner(true);
};

  return (
    <ThemeProvider theme={theme}>
      {showBanner ? (
        <Banner
          message={responseMessage}
          severity="info"
          open={true}
        />
      ) : (
        ''
      )}
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              'url(https://www.codehawke.com/img/banner/hawk.webp)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                id="new-password"
                autoComplete="new-password"
                onChange={(e) => setNewPassword(e.target.value)}
                error={error}
                helperText={error && errorMessage}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm New Password"
                type="password"
                id="confirm-new-password"
                autoComplete="new-password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={error && errorMessage === 'Passwords do not match.'}
                helperText={
                  error && errorMessage === 'Passwords do not match.'
                    ? errorMessage
                    : ''
                }
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Reset Password
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/login" variant="body2">
                    Remembered your password? Sign in
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export { ResetPassword };
