import * as React from 'react';
import { useState, useRef } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright } from "../headersMessaging/copyright";
import { Banner } from '../../components/headersMessaging/banner';

const theme = createTheme();

function SignInSide() {
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [showBanner, setShowBanner] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const displayBanner = (message: string) => {
    setResponseMessage(message);
    setShowBanner(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    let token = '';
    try {
      // Check if grecaptcha is available
      if (window.grecaptcha) {
        token = await window.grecaptcha.execute("6Le9aOApAAAAAJhEPsPEMFDhda3_nmKRMLE1b3zB", { action: 'register' });
      } else {
        console.warn("reCAPTCHA not loaded");
      }
    } catch (error) {
      console.error("reCAPTCHA error:", error);
    }

    if (!token && window.grecaptcha) {
      displayBanner('Please complete the CAPTCHA');
      return;
    }

    setError(false);
    
    // Get values from refs
    const username = emailRef.current?.value || '';
    const password = passwordRef.current?.value || '';

    fetch('/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username: username, password: password, captchaToken: token })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      if (data.access_token) {
        localStorage.setItem('token', data.access_token);
        document.cookie = `token=${data.access_token}`;
        window.location.href = '/profile';
      } else {
        setError(true);
        setErrorMessage(data.error || 'Incorrect username or password');
      }
    })
    .catch(error => {
      console.error('Login error:', error);
      setError(true);
      setErrorMessage('An error occurred during login. Please try again.');
    });
  };

  return (
    <ThemeProvider theme={theme}>
      {showBanner && (
        <Banner
          message={responseMessage}
          severity={"error"}
          open={true}
        />
      )}
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(/img/banner/hawk.webp)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={error}
                helperText={error ? errorMessage : ''}
                inputRef={emailRef}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={error}
                helperText={error ? errorMessage : ''}
                inputRef={passwordRef}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/forgot" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/register" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export { 
    SignInSide
};
