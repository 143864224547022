import * as React from "react";
import { SignInSide } from "../components/forms/SignInSide";
import { Register } from "../components/forms/Register";
import { ChangePassword } from "../components/forms/ChangePassword";
import { ForgotPassword } from "../components/forms/ForgotPassword";
import { FormType } from "../types/user";
import { ResetPassword } from "../components/forms/ResetPassword";

export type UserPageProps = {
    formType: FormType;
};

const UserPage = (props: UserPageProps) => {

    const setForm = () => {
        switch (props.formType) {
            case FormType.SIGN_IN:
                return <SignInSide />;
            case FormType.REGISTER:
                return <Register />;
            case FormType.CHANGE_PASSWORD:
                return <ChangePassword />;
            case FormType.FORGOT_PASSWORD:
                return <ForgotPassword />;
            case FormType.RESET_PASSWORD:
                return <ResetPassword />;
            default:
                return <div>Form Type Not Supported</div>;
        }
    };

    return (
        <React.Fragment>
            {setForm()}
        </React.Fragment>
    );
};

export { UserPage };
