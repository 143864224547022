import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright } from '../headersMessaging/copyright';
import { Banner } from '../../components/headersMessaging/banner';

const theme = createTheme();

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState('');
  const [showBanner, setShowBanner] = React.useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(false);

    // Retrieve the token from localStorage
    const token = localStorage.getItem('token');

    if (token) {
      fetch('/auth/change_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ currentPassword, newPassword }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            displayBanner(data.message);
            setTimeout(() => {
              window.location.href = '/profile';
            }, 5000);
          } else {
            setError(true);
            setErrorMessage(data.error || 'Failed to change password');
            displayBanner(data.error || 'Failed to change password');
          }
        });
    }
  };

  const displayBanner = (message: string) => {
    setResponseMessage(message);
    setShowBanner(true);
  };

  return (
    <ThemeProvider theme={theme}>
      {showBanner ? (
        <Banner message={responseMessage} severity="info" open={true} />
      ) : (
        ''
      )}
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              'url(https://www.codehawke.com/img/banner/hawk.webp)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Change Password
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="currentPassword"
                label="Current Password"
                type="password"
                id="current-password"
                autoComplete="current-password"
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                id="new-password"
                autoComplete="new-password"
                onChange={(e) => setNewPassword(e.target.value)}
                error={error}
                helperText={error ? errorMessage : ''}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Update Password
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/profile" variant="body2">
                    Return to Profile
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export { ChangePassword };
